
<header class="header-after-login">
  <nav id="navbar-example2" class="navbar navbar-expand-lg  navbar-light bg-primary">
    <div class="container-fluid">

      <a class="navbar-brand" href="/"><img src="assets/images/logo-white.png"></a>
      <ul class="navbar-nav ms-auto flex-nowrap d-flex mobile-nav">
        <li class="nav-item">
          <a class="nav-link text-white" routerLink="/about-us">About Us </a>
        </li>

      </ul>
    </div>
  </nav>
</header>

<router-outlet></router-outlet>
