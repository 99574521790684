import { Component, OnInit,Output, EventEmitter} from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general/general.service';
import { NgxAgoraService, Stream, AgoraClient, ClientEvent, StreamEvent } from 'ngx-agora';
import AgoraRTM from 'agora-rtm-sdk';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

declare var $: any;
@Component({
  selector: 'app-call-screen',
  templateUrl: './call-screen.component.html',
  styleUrls: ['./call-screen.component.css']
})
export class CallScreenComponent implements OnInit {
  appointmentId:string = '';
   options:any = {
    channel: 'appoinmentChannel64423258',
    uid: "",
    token: ""
  };

  mediaConttrol: any = {
    mic: true,
    video: true
  }
  localClient: any;
  channel: any;
  
  private client: AgoraClient;
  private localStream: Stream;
  localCallId = 'agora_local';
  remoteCalls: string[] = [];
  width: any = '100%';
  height: any = '100%';
  screenShareActivePin: any = false;
  screenShareActive: any = false;
  AgoraAppId:any = environment.AgoraAppID;
  @Output() customEvent = new EventEmitter<string>();
  title="Kokoro Call";
  constructor(
    private route: ActivatedRoute,
    private _gs:GeneralService,
    private ngxAgoraService: NgxAgoraService,
    private titleService:Title,
    private router: Router
  ) {
    this.localClient = AgoraRTM.createInstance(this.AgoraAppId);
    this.channel = this.localClient.createChannel(this.options.channel);
   }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.appointmentId = this.route.snapshot.queryParamMap.get('targetId');  
    
  }


  startCall(){
    this._gs.postMethod({targetId:this.appointmentId},'generateAgoraTokenMobile').subscribe((data: any) => {
      console.log({data});
      if (data.status) {
        this.options.channel = data.data.channel;
        this.options.token = data.data.token;
        this.options.uid = data.data.uid;
        // alert(`
        //         channel : ${data.data.channel} \n
        //         token : ${data.data.token} \n
        //         uid : ${data.data.uid} \n
        // `);
        this.startAgoraCall();
      }else{
        // alert("try later!");
      }
    }
  )
  }

  /**
   * Agora Call Methods 
   */
  startAgoraCall() {
    this.mediaConttrol.mic = true;
    this.mediaConttrol.video = true;
    this.client = this.ngxAgoraService.createClient({ mode: 'rtc', codec: 'vp8' });
    this.assignClientHandlers();

    // Added in this step to initialize the local A/V stream
    this.localStream = this.ngxAgoraService.createStream({ streamID: this.options.uid, audio: true, video: true, screen: false });
    this.assignLocalStreamHandlers();
    this.initLocalStream(() => this.join(uid => this.publish(), error => console.error(error)));
    $('#startcallModal').modal({
      backdrop: 'static',
      keyboard: false
    });
    $("#startcallModal").modal('show');
  }

  leaveCall() {
    this.client.leave(() => {
      $("#startcallModal").modal('hide');
      this.localStream.close();
      this.localStream.stop();
      // this.localClient.logout();
      //this.channel.leave();
      this.remoteCalls = [];
      const event = new Event('kokoro-call-end');
      window.dispatchEvent(event);
      this.customEvent.emit('kokoro-call-end');
      this.router.navigate(['thank-you']);
      
    }, (err) => {
      console.log("Leave channel failed");
    });
  }

  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      console.log('Publish local stream successfully');
    });

    this.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      // debugger
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      // debugger

      if (!this.remoteCalls.includes(id)) {
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });

    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = [];
        console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
        var sws_tiles = (100 / this.remoteCalls.length);
        this.width = sws_tiles + '%';
        this.height = sws_tiles + '%';
        console.log(`${evt.uid} left from this channel`);
        let screenPinID=  localStorage.getItem('screenPinID');
        // console.log(screenPinID,`${stream.getId()}`)
        // debugger
        if(screenPinID==`${this.getRemoteId(stream)}`){
          this.screenShareActivePin = false;
        $("#" + this.localCallId).removeClass('localRightPin');
        }

        // this.leaveCall(); 
        $("#leavethiscall").trigger( "click" );
      }
    });
  }

  
  private assignLocalStreamHandlers(): void {
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
      // alert("accessAllowed");
      console.log('accessAllowed');
    });

    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      // alert("accessDenied");
      console.log('accessDenied');
    });
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.init(
      () => {
        // The user has granted access to the camera and mic.
        this.localStream.play(this.localCallId);
        if (onSuccess) {
          onSuccess();
        }
      },
      err => {console.error('getUserMedia failed', err); 
      // alert("getUserMedia failed");
    }
    );
  }

  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    this.client.join(this.options.token, this.options.channel, this.options.uid, onSuccess, onFailure);
  }

  publish(): void {
    this.client.publish(this.localStream, err => console.log('Publish local stream error: ' + err));
  }

  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }

  mute(input: any) {
    if (input == 0) {
      this.mediaConttrol.mic = !this.mediaConttrol.mic;
      if (this.mediaConttrol.mic) {
        this.localStream.unmuteAudio();

      } else {
        this.localStream.muteAudio();
      }

    } else {
      this.mediaConttrol.video = !this.mediaConttrol.video;
      if (this.mediaConttrol.video) {
        this.localStream.unmuteVideo();
      } else {
        this.localStream.muteVideo();

      }
    }
  }

}
