import { NgModule } from '@angular/core';
import { BrowserModule,Title  } from '@angular/platform-browser';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CallScreenComponent } from './call-screen/call-screen.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { NgxAgoraModule, AgoraConfig } from 'ngx-agora';
import { environment } from 'src/environments/environment';
import { ThankYouComponent } from './thank-you/thank-you.component';

const agoraConfig: AgoraConfig = {
  AppID: environment.AgoraAppID,
};

@NgModule({
  declarations: [
    AppComponent,
    CallScreenComponent,
    HomeComponent,
    AboutComponent,
    ThankYouComponent
  ],
  imports: [
    BrowserModule,    
    AppRoutingModule,
    HttpClientModule,
    NgxAgoraModule.forRoot(agoraConfig),
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
