<section class="wrap20">
    <div class="dashboardMain bg-white">
        <div class="dashboard-boxx mb-4">
            <div class="txt_dec">
                <h4 class="fw500">Please make sure that you follow these instructions</h4>
                <p>Please make sure that permissions for camera and mic are on in the settings<br>(कृपया सुनिश्चित करें
                    कि आपने कैमरा और माइक के लिए अनुमतियाँ दे दी हैं)</p>
                <p>Please sit in a well lit area for better experience<br>(बेहतर अनुभव के लिए कृपया अच्छी रोशनी वाली जगह
                    पर बैठें)</p>
                <p>Please wait for 2 minutes to 5 minutes as our doctors may be busy<br>(कृपया 2 मिनट से 5 मिनट तक
                    प्रतीक्षा करें क्योंकि हमारे डॉक्टर व्यस्त हो सकते हैं)</p>
                <p>Please do NOT press the refresh button(unless the doctor specifically asks you to do so)<br>(कृपया
                    रिफ्रेश बटन न दबाएं (जब तक कि डॉक्टर विशेष रूप से आपको ऐसा करने के लिए न कहे))</p>
            </div>
        </div>
        <div class="row form-box justify-content-center" *ngIf="appointmentId">
            <div class="col-6 col-sm-4 col-md-4 col-lg-3">
                <button type="button" class="btn btn-primary btn-submit btn-block" (click)="startCall()">Start the
                    Consultation</button>
            </div>
        </div>
    </div>
</section>


<!-- Agora Video Model -->
<div class="modal fade bd-example-modal-lg" id="startcallModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl window_pop" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="videomaillass">
              <div class="local_call_main">
                <div class="local_call" [id]="localCallId">
                  <ul class="videoControls2">
                    <li (click)="screensharePin(localCallId,true)"
                      *ngIf="screenShareActivePin">
                      <i class="bi bi-pin"></i>
                    </li>
                    <!-- <li (click)="screenshareUnpin(localCallId,true)" *ngIf="(uiidpin==localCallId) && !screenShareActivePin">
                      <i class="bi bi-pin-fill"></i>
                    </li> -->
                  </ul>
                </div>
              </div>
              <div class="mainvideodiv">
                <!-- <div *ngFor="let callId of remoteCalls" [id]="callId" class="remote" style="width: 100%;height: 100%;">
                </div> -->
                <div *ngFor="let callId of remoteCalls" [id]="callId"
                [ngClass]="[(uiid==callId)?'remote':'remote', ((uiidpin==callId) && screenShareActivePin)?'pintoScreen':'']"
                [style.width]="width" [style.height]="height">
                <ul class="videoControls2">
                  <!-- <li id="ppm{{callId}}" (click)="screensharePin(callId,false)"
                    *ngIf="(uiidpin!==callId) || !screenShareActivePin">
                    <i class="bi bi-pin"></i>
                  </li> -->
                  <!-- <li id="pp{{callId}}" (click)="screenshareUnpin(callId,false)"
                    *ngIf="(uiidpin==callId) && screenShareActivePin">
                    <i class="bi bi-pin-fill"></i>
                  </li> -->
                </ul>
              </div>
                <!-- <ul class="local_call_right list-inline">
                  <li *ngFor="let callId of remoteCalls" [id]="callId">
                  </li>
                </ul> -->
                <div>
                </div>
              </div>
              <ul class="videoControls">
                <li (click)="mute(0)"><i [ngClass]="(mediaConttrol.mic)?'bi bi-mic':'bi bi-mic-mute-fill'"></i></li>
                <li (click)="mute(1)"><i
                    [ngClass]="(mediaConttrol.video)?'bi bi-camera-video':'bi bi-camera-video-off'"></i></li>
                <li (click)="leaveCall()" id="leavethiscall"> <i class="bi bi-telephone"></i></li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>