import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallScreenComponent } from './call-screen/call-screen.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutComponent },
  { path: 'call-screen', component: CallScreenComponent },
  { path: 'thank-you', component: ThankYouComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
