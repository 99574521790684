import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private http: HttpClient) { }


  globalForgotHeaders()
  {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    return httpOptions;
  }

  postMethod(data:object,URL:any){
    const url = `${environment.BaseURL}${URL}`;
    let headers = this.globalForgotHeaders();
    return this.http.post(url,data,headers);
  }
  getMethod(URL:any){
    const url = `${environment.BaseURL}${URL}`;
    return this.http.get(url);
  }

}
